import { css } from "@emotion/react";
import { rgba } from "polished";

export type Mode = "dark" | "light";

export const ButtonReset = css`
  touch-action: manipulation;
  user-select: none;
  border: none;
  outline: none;
  ::-moz-focus-inner {
    border: 0;
  }
`;

const Breakpoints = [768, 874, 1024, 1200];
const Responsive = {
  xs: 0,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4,
  breakpoints: Breakpoints,
  bp: {
    xs: Breakpoints[0],
    sm: Breakpoints[1],
    md: Breakpoints[2],
    lg: Breakpoints[3],
  },
};

const MediaQuery = {
  xs: `@media screen and (max-width: ${Responsive.bp.xs}px)`,
  sm: `@media screen and (min-width: ${Responsive.bp.xs + 0.99}px) and (max-width: ${Responsive.bp.sm}px)`,
  md: `@media screen and (min-width: ${Responsive.bp.sm + 0.99}px) and (max-width: ${Responsive.bp.md}px)`,
  lg: `@media screen and (min-width: ${Responsive.bp.md + 0.99}px) and (max-width: ${Responsive.bp.lg}px)`,
  xl: `@media screen and (min-width: ${Responsive.bp.lg + 0.99}px)`,
  sm_: `@media screen and (min-width: ${Responsive.bp.xs + 0.99}px)`,
  md_: `@media screen and (min-width: ${Responsive.bp.sm + 0.99}px)`,
  lg_: `@media screen and (min-width: ${Responsive.bp.md + 0.99}px)`,
  _sm: `@media screen and (max-width: ${Responsive.bp.sm}px)`,
  _md: `@media screen and (max-width: ${Responsive.bp.md}px)`,
  _lg: `@media screen and (max-width: ${Responsive.bp.lg}px)`,
};

const Fonts = {
  bold: css`
    font-family: Gilroy-Bold, sans-serif;
    font-weight: 700;
  `,
  medium: css`
    font-family: Gilroy-Medium, sans-serif;
    font-weight: 500;
  `,
  regular: css`
    font-family: Gilroy-Regular, sans-serif;
    font-weight: 400;
  `,
};

const fontSize = (size: number, lineHeight: number, factor = 1.15) => css`
  ${MediaQuery.xs} {
    font-size: ${Math.round(size / factor)}px;
    line-height: ${Math.round(lineHeight / factor)}px;
  }

  ${MediaQuery.sm_} {
    font-size: ${size}px;
    line-height: ${lineHeight}px;
  }
`;

const FontSizes = {
  h1: fontSize(72, 84, 1.5),
  h2: fontSize(54, 72, 1.5),
  h3: fontSize(42, 54, 1.4),
  h4: fontSize(36, 48, 1.2),
  h5: fontSize(32, 42),
  h6: fontSize(24, 36),
  body: fontSize(16, 24),
  link: fontSize(16, 24),
  navlink: fontSize(16, 30),
  p: fontSize(18, 30),
  control: fontSize(14, 24),
  large: fontSize(20, 32),
  medium: fontSize(16, 30),
  small: fontSize(14, 24),
  legal: fontSize(12, 18),
  caps: css`
    ${fontSize(12, 24)};
    text-transform: uppercase;
    letter-spacing: 3.6px;
  `,
};

const Palette = {
  text: "#FFFFFF",
  background: "#060911",
  primary: "#FF8759",
  secondary: "#E19B00",
  active: "#FFB954",
  muted: "#888B97",
  neon: "#00FAE8",
  blue: "#4FAEFF",
  light: "#DEE0E6",
  gray: "#6B7690",
  dark: "#26272B",
  border: "#54555A",
  glow: "#E0E2FF",
};

const Gradients = {
  primary: `linear-gradient(95deg, ${Palette.primary} -15%, ${Palette.secondary} 105%)`,
  border: `linear-gradient(90deg, ${Palette.dark} 70%, ${Palette.border} 100%)`,
};

// NB: backdrop-filter is not supported in FF
export const BackdropFilter = css`
  background: ${rgba(Palette.background, 0.25)};
  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    backdrop-filter: blur(40px);
  }
`;

const headerSpacing = (before: number, after: number) => css`
  margin-top: ${before}px;
  & + * {
    margin-top: ${after}px;
  }
`;

const Typography = {
  h1: css`
    ${Fonts.bold};
    ${FontSizes.h1};
    color: ${Palette.text};
  `,
  h2: css`
    ${Fonts.bold};
    ${FontSizes.h2};
    color: ${Palette.text};
  `,
  h3: css`
    ${Fonts.bold};
    ${FontSizes.h3};
    color: ${Palette.text};
  `,
  h4: css`
    ${Fonts.bold};
    ${FontSizes.h4};
    color: ${Palette.text};
  `,
  h5: css`
    ${Fonts.bold};
    ${FontSizes.h5};
    color: ${Palette.text};
  `,
  h6: css`
    ${Fonts.bold};
    ${FontSizes.h6};
    color: ${Palette.text};
  `,
  a: css`
    ${Fonts.regular};
    ${FontSizes.link};
    color: ${Palette.text};
    text-decoration: none;
  `,
  control: css`
    ${Fonts.medium};
    ${FontSizes.control};
    color: ${Palette.text};
    text-decoration: none;
  `,
  caps: css`
    ${Fonts.bold};
    ${FontSizes.caps};
    color: ${Palette.text};
  `,
};

const BodyText = css`
  strong {
    ${Fonts.bold};
  }

  h1,
  h2,
  h3 {
    ${Fonts.bold};
    color: ${Palette.text};
  }

  h4,
  h5,
  h6 {
    ${Fonts.medium};
    color: ${Palette.text};
  }

  h1 {
    ${fontSize(54, 72)};
    ${headerSpacing(72, 30)};
  }

  h2 {
    ${fontSize(48, 60)};
    ${headerSpacing(60, 24)};
  }

  h3 {
    ${fontSize(39, 51)};
    ${headerSpacing(42, 24)};
  }

  h4 {
    ${fontSize(30, 42)};
    ${headerSpacing(36, 24)};
  }

  h5 {
    ${fontSize(24, 36)};
    ${headerSpacing(32, 24)};
  }

  h6 {
    ${fontSize(18, 30)};
    ${headerSpacing(24, 24)};
  }

  a {
    ${Fonts.regular};
    ${FontSizes.link};
    color: ${Palette.light};
    text-decoration: none;

    &:hover,
    &:focus {
      color: ${Palette.text};
    }
  }

  em {
    font-style: italic;
  }

  hr {
    margin: 48px 0;
    border-color: ${Palette.dark};
  }

  p + p {
    margin-top: 30px;
  }

  > ul {
    margin: 24px 0;
    list-style-type: disc;

    > li {
      margin: 12px 0;
    }
  }

  > ol,
  > ol > li > ol {
    counter-reset: section;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
  }

  > ol > li > ol > li {
    padding: 0 0 24px 48px;
    &::before {
      ${FontSizes.body};
      color: ${Palette.muted};
      counter-increment: section;
      content: counters(section, ".") "";
      text-align: left;
      position: absolute;
      left: 0;
    }
  }

  > ol > li {
    > p {
      ${FontSizes.h6};
      ${Fonts.medium};
      color: ${Palette.text};
      margin: 24px 0 16px 48px;
    }

    &::before {
      ${FontSizes.h6};
      ${Fonts.medium};
      color: ${Palette.text};
      counter-increment: section;
      content: counters(section, ".") ". ";
      float: left;
    }
  }
`;

const Theme = {
  palette: Palette,
  gradients: Gradients,
  fonts: Fonts,
  fontSizes: FontSizes,
  typography: {
    ...Typography,
    body: {
      regular: css`
        ${Fonts.regular};
        ${FontSizes.body};
        color: ${Palette.muted};
        ${BodyText};
      `,
      medium: css`
        ${Fonts.medium};
        ${FontSizes.body};
        color: ${Palette.muted};
        ${BodyText};
      `,
      bold: css`
        ${Fonts.bold};
        ${FontSizes.body};
        color: ${Palette.muted};
        ${BodyText};
      `,
    },
    p: css`
      ${Fonts.medium};
      ${FontSizes.p};
      color: ${Palette.muted};
      ${BodyText};
    `,
  },
  opacity: {
    muted: 0.6,
  },
  zIndex: {
    header: 1000,
    background: -100,
  },
  mq: MediaQuery,
  page: {
    ...Responsive,
    padding: [18, 36, 54, 96, 108],
    headerHeight: [56, 160],
  },
  section: {
    padding: [48, 72, 96, 108, 120],
  },
};

export const Anchor = css`
  display: block;
  content: " ";
  pointer-events: none;
  visibility: hidden;

  ${Theme.mq.xs} {
    margin-top: -${Theme.page.headerHeight[Theme.page.xs]}px;
    height: ${Theme.page.headerHeight[Theme.page.xs]}px;
  }
  ${Theme.mq.sm_} {
    margin-top: -${Theme.page.headerHeight[Theme.page.sm]}px;
    height: ${Theme.page.headerHeight[Theme.page.sm]}px;
  }
`;

export default Theme;
